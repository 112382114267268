import isNil from 'lodash/fp/isNil';
import * as yup from 'yup';

export const multifactorBasedSchema = yup.object({
  longAssets: yup.number().min(0).required(),
  shortAssets: yup.number().min(0).required(),
  minFactors: yup
    .number()
    .min(1)
    .required()
    .test('valid-minNumberOfFactors', "Can't be greater than the number of factors", (value, context) => {
      if (!value) {
        return true;
      }

      const multifactor = context.parent.multifactor;
      if (isNil(multifactor)) {
        return true;
      }

      return multifactor.maxFactors >= value;
    }),
  multifactor: yup.object().required(),
});

export interface MultifactorBasedFormOutputFields {
  longAssets: number;
  shortAssets: number;
  minFactors: number;
  multifactor: {
    maxFactors: number;
    multifactor: {
      id: number;
      name: string;
    };
  };
}
