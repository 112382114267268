import type { FilterModel } from 'ag-grid-community';
import { isValidDayjsDate } from 'components/date.utils';
import { MAX_DRAWDOWN, MAX_DRAWDOWN_1Y, DRAWDOWN, metricsData } from 'components/metrics/NonPortfolioMetricsData';
import type { FormInputType } from 'components/technical/form/Form.types';
import type { MetricColumnConfig } from 'components/technical/grids/SharedReportColumns';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import type { Dayjs } from 'dayjs';
import {
  IFilterOperator,
  IFilterType,
  type IRebalancedPortfolioFilterInput,
  INumericFilterConditionOperator,
  type IFactor,
  type IMultiFactor,
  type IMultiFactorDetails,
  type IMultiFactorWeight,
  type IScreenerFactorInput,
} from 'generated/graphql';
import { isFunction, isString } from 'lodash/fp';
import * as yup from 'yup';

export const DEFAULT_FACTOR_WEIGHT = {
  metricId: null,
  weight: null,
  betterIfLower: false,
};

export const DEFAULT_SCREENER = {
  minNumberOfFactors: '0',
  multifactorId: null,
};

export const CREATE_OR_UPDATE_MULTIFACTOR_FORM_SCHEMA = yup.object({
  name: yup.string().required(),
  description: yup.string(),
  factorWeights: yup.array().of(
    yup.object().shape({
      metricId: yup
        .number()
        .required()
        .test('unique-metricId', 'Metrics must be unique', (value, context) => {
          if (!value) return true;
          const duplicates = context.from![1].value.factorWeights.filter(
            (factorWeight: IMultiFactorWeight) => factorWeight.metricId === value
          );

          return duplicates.length < 2;
        }),
      weight: yup
        .number()
        .required()
        .moreThan(0, 'Must be greater than 0')
        .max(100, 'Must be less than or equal to 100'),
      betterIfLower: yup.boolean().required(),
    })
  ),
});

export type CreateOrUpdateMultifactorFormOutputFields = {
  name: string;
  description: string;
  factorWeights: Array<{
    betterIfLower: boolean;
    metricId: number;
    weight: number;
  }>;
};

export type CreateOrUpdateMultifactorFormInputFields = FormInputType<CreateOrUpdateMultifactorFormOutputFields>;

export type LoadMultifactorFormOutputFields = {
  date: Dayjs;
  screeners: { minNumberOfFactors: number; multifactorId: number }[];
};

export type LoadMultifactorFormInputFields = FormInputType<LoadMultifactorFormOutputFields>;

type MultiFactorDetails = Omit<IMultiFactorDetails, 'multifactor'> & {
  multifactor: Pick<IMultiFactor, 'id'>;
};

export const getLoadMultifactorFormSchema = (
  multifactors: MultiFactorDetails[]
): yup.ObjectSchema<
  {
    date: unknown;
    screeners:
      | {
          minNumberOfFactors: number;
          multifactorId: number;
        }[]
      | undefined;
  },
  yup.AnyObject,
  {
    date: undefined;
    screeners: '';
  },
  ''
> =>
  yup.object({
    date: yup.mixed().required().test('validDate', 'Date is invalid', isValidDayjsDate),
    screeners: yup.array().of(
      yup
        .object({
          minNumberOfFactors: yup
            .number()
            .required()
            .min(1, 'Must be greater than 1')
            .test('valid-minNumberOfFactors', "Can't be greater than the number of factors", (value, context) => {
              if (!value) return true;
              return multifactors.find((mf) => mf.multifactor.id === context.parent.multifactorId)!.maxFactors >= value;
            }),
          multifactorId: yup
            .number()
            .required()
            .test('unique-multifactorId', 'Multifactors must be unique', (value, context) => {
              if (!value) return true;
              const duplicates = context.from![1].value.screeners.filter(
                (screener: IScreenerFactorInput) => screener.multifactorId === value
              );

              return duplicates.length < 2;
            }),
        })
        .required()
    ),
  });

export const createFactorSelectOptions = (factors: IFactor[]): StaticAutocompleteOption<number>[] => {
  return factors.map((factor) => {
    const metricObj = metricsData[`met:${factor.name}`];
    let labelName: string;
    if (isString(metricObj?.name)) {
      labelName = metricObj.name;
    } else if (isFunction(metricObj?.name)) {
      labelName = metricObj.name();
    } else {
      labelName = factor.name;
    }
    return {
      searchText: labelName,
      label: labelName,
      key: factor.id.toString(),
      value: factor.id,
      groupBy: () => factor.category,
    };
  });
};

export const groupFactorsByCategory = (value: StaticAutocompleteOption<number>): string => {
  if (value.groupBy) {
    return value.groupBy();
  }
  return 'No category';
};

export const metricColumnsConfiguration: Record<string, MetricColumnConfig[]> = {
  Fundamentals: [
    { metricLabel: 'met:market_cap', initialHide: false },
    { metricLabel: 'met:total_val_locked', initialHide: false },
    { metricLabel: 'met:volume-24h', initialHide: false },
    { metricLabel: 'met:liquidity', initialHide: false },
    { metricLabel: 'met:circulating_supply', initialHide: false },
    { metricLabel: 'met:current_supply', initialHide: false },
    { metricLabel: 'met:max_supply', initialHide: false },
    { metricLabel: 'met:network_val_adj_transfer_val', initialHide: false },
    { metricLabel: 'met:volume-24h', initialHide: false },
    { metricLabel: 'met:active_address_cnt', initialHide: false },
    { metricLabel: 'met:realized_cap_usd', initialHide: false },
    { metricLabel: 'met:supply_free_float', initialHide: true },
    { metricLabel: 'met:stock_to_flow_ratio', initialHide: false },
    { metricLabel: 'met:network_distribution_factor', initialHide: false },
  ],
  Drawdown: [
    { metricLabel: MAX_DRAWDOWN, initialHide: false },
    { metricLabel: MAX_DRAWDOWN_1Y, initialHide: false },
    { metricLabel: DRAWDOWN, initialHide: false },
  ],
  '30 Days': [
    {
      metricLabel: 'met:rolling_volatility-30',
      initialHide: false,
    },
    { metricLabel: 'met:rolling_beta_btc-30', initialHide: false },
    { metricLabel: 'met:rolling_beta_eth-30', initialHide: false },
    { metricLabel: 'met:rolling_beta_sol-30', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-30', initialHide: true },
    { metricLabel: 'met:rolling_sortino-30', initialHide: true },
    { metricLabel: 'met:rolling_var95-30', initialHide: true },
    { metricLabel: 'met:rolling_var99-30', initialHide: true },
    { metricLabel: 'met:rolling_skewness-30', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-30', initialHide: true },
    { metricLabel: 'met:rolling_momentum-30', initialHide: true },
    { metricLabel: 'met:rolling_idio_vol-30', initialHide: true },
  ],

  '60 Days': [
    { metricLabel: 'met:rolling_volatility-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_sol-60', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-60', initialHide: true },
    { metricLabel: 'met:rolling_sortino-60', initialHide: true },
    { metricLabel: 'met:rolling_var95-60', initialHide: true },
    { metricLabel: 'met:rolling_var99-60', initialHide: true },
    { metricLabel: 'met:rolling_skewness-60', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-60', initialHide: true },
    { metricLabel: 'met:rolling_momentum-60', initialHide: true },
    { metricLabel: 'met:rolling_idio_vol-60', initialHide: true },
  ],
  '90 Days': [
    { metricLabel: 'met:rolling_volatility-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_sol-90', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-90', initialHide: true },
    { metricLabel: 'met:rolling_sortino-90', initialHide: true },
    { metricLabel: 'met:rolling_var95-90', initialHide: true },
    { metricLabel: 'met:rolling_var99-90', initialHide: true },
    { metricLabel: 'met:rolling_skewness-90', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-90', initialHide: true },
    { metricLabel: 'met:rolling_momentum-90', initialHide: true },
    { metricLabel: 'met:rolling_idio_vol-90', initialHide: true },
  ],
  '365 Days': [
    { metricLabel: 'met:rolling_momentum-365', initialHide: true },
    { metricLabel: 'met:rolling_idio_vol-365', initialHide: true },
  ],
  'Network Activity': [
    { metricLabel: 'met:address_balance_1in1m_count', initialHide: false },
    { metricLabel: 'met:address_balance_count', initialHide: false },
    { metricLabel: 'met:asset_completion_time', initialHide: true },
    { metricLabel: 'met:transaction_count', initialHide: false },
    { metricLabel: 'met:transaction_count_second', initialHide: true },
    { metricLabel: 'met:transfer_count', initialHide: true },
    { metricLabel: 'met:transfer_value_adjusted_native', initialHide: true },
    { metricLabel: 'met:transfer_value_adjusted_usd', initialHide: true },
    { metricLabel: 'met:velocity_1year', initialHide: true },
  ],
  'Mining & Fees': [
    { metricLabel: 'met:fee_mean_native', initialHide: true },
    { metricLabel: 'met:fee_mean_usd', initialHide: true },
    { metricLabel: 'met:fee_total_usd', initialHide: true },
    { metricLabel: 'met:hash_rate', initialHide: true },
    { metricLabel: 'met:hash_rate_30d', initialHide: true },
    { metricLabel: 'met:issuance_continuous_native', initialHide: true },
    { metricLabel: 'met:revenue_hash_rate_native', initialHide: true },
    { metricLabel: 'met:revenue_hash_rate_usd', initialHide: true },
    { metricLabel: 'met:revenue_native', initialHide: true },
    { metricLabel: 'met:revenue_usd', initialHide: true },
  ],
  'Supply Distribution': [
    { metricLabel: 'met:supply_active_1d', initialHide: true },
    { metricLabel: 'met:supply_top_10_percent', initialHide: true },
    { metricLabel: 'met:supply_top_1_percent', initialHide: true },
    { metricLabel: 'met:supply_miner_0hop_all_native', initialHide: true },
    { metricLabel: 'met:supply_miner_0hop_all_usd', initialHide: true },
  ],
};

const mapTypeToFilterConditionOperator = (type: string) => {
  switch (type) {
    case 'notBlank':
      return INumericFilterConditionOperator.NotBlank;
    case 'blank':
      return INumericFilterConditionOperator.Blank;
    case 'equals':
      return INumericFilterConditionOperator.Eq;
    case 'notEqual':
      return INumericFilterConditionOperator.Neq;
    case 'greaterThan':
      return INumericFilterConditionOperator.Gt;
    case 'greaterThanOrEqual':
      return INumericFilterConditionOperator.Gte;
    case 'lessThan':
      return INumericFilterConditionOperator.Lt;
    case 'lessThanOrEqual':
      return INumericFilterConditionOperator.Lte;
    case 'inRange':
      return INumericFilterConditionOperator.InRange;
    default:
      throw new Error(`Unsupported filter condition operator: ${type}`);
  }
};

export const convertGridStateToFilters = (filterModel: FilterModel): IRebalancedPortfolioFilterInput[] => {
  return Object.entries(filterModel).map(([colId, filter]): IRebalancedPortfolioFilterInput => {
    if (filter.filterType === 'set') {
      return {
        colId,
        filterType: IFilterType.Set,
        values: filter.values,
      };
    }

    if (filter.type) {
      return {
        colId,
        filterType: IFilterType.Number,
        operator: filter.operator,
        conditions: [
          {
            type: mapTypeToFilterConditionOperator(filter.type),
            filter: filter.filter,
            filterTo: filter.filterTo,
          },
        ],
      };
    }

    return {
      colId,
      filterType: IFilterType.Number,
      operator: filter.operator === 'OR' ? IFilterOperator.Or : IFilterOperator.And,
      // biome-ignore lint/suspicious/noExplicitAny: aggrid doesnt offer typing for condition
      conditions: (filter.conditions ?? []).map((condition: any) => ({
        type: mapTypeToFilterConditionOperator(condition.type),
        filter: condition.filter,
        filterTo: condition.filterTo,
      })),
    };
  });
};
