import { Stack } from '@mui/joy';
import {
  type IMultifactorScores,
  IPortfolioDefinitionSubType,
  useComputeMultifactorScoresLazyQuery,
  useMultifactorSuspenseQuery,
} from 'generated/graphql';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { type ReactElement, type ReactNode, Suspense, useRef } from 'react';
import {
  convertGridStateToFilters,
  type LoadMultifactorFormInputFields,
  type LoadMultifactorFormOutputFields,
} from './AssetScreenerService';
import AssetScreenerTable from './AssetScreenerTable';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import Loader from 'components/technical/Loader/Loader';
import LoadMultifactorDialog from './LoadMultifactorDialog';
import type { AgGridReact } from 'ag-grid-react';
import type { AssetRowType } from './AssetScreenerTable';
import { Save } from '@mui/icons-material';
import CreateRebalancedPortfolioDialog from 'components/copilot/lab/definition/rebalancedPortfolio/CreateRebalancedPortfolioDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'UseAuth.tsx';

function AssetScreenerTab(): ReactNode {
  const ref = useRef<AgGridReact<AssetRowType>>(null);
  const getAllMultifactorsForUserQuery = useMultifactorSuspenseQuery();
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const [fetchScores, { data: computedScores, loading: isComputedScoresLoading }] =
    useComputeMultifactorScoresLazyQuery();

  const handleSubmit = (data: LoadMultifactorFormInputFields): void => {
    const outData = data as unknown as LoadMultifactorFormOutputFields;
    fetchScores({
      variables: {
        input: {
          date: convertDateInUtcToUTCISODate(outData.date),
          screeners: outData.screeners,
        },
      },
    });
  };

  if (isComputedScoresLoading) {
    return <Loader />;
  }

  return (
    <Stack spacing={1.5} height="100%">
      <Stack direction="row" justifyContent="flex-end" gap={1}>
        {isAdmin && (
          <DialogButton
            variant="soft"
            width="small"
            renderDialog={({ onClose }): ReactElement => {
              const filters = convertGridStateToFilters(ref.current?.api.getFilterModel() ?? {});
              return (
                <CreateRebalancedPortfolioDialog
                  onClose={onClose}
                  onAdded={() => {
                    navigate('/app/copilot/lab/portfolio');
                    onClose();
                  }}
                  subType={IPortfolioDefinitionSubType.MultifactorBased}
                  multifactors={getAllMultifactorsForUserQuery.data.multifactor.getAllMultifactorsForUser}
                  composition={[]}
                  filters={filters}
                />
              );
            }}
            startDecorator={<Save />}
          >
            Save portfolio*
          </DialogButton>
        )}
        <DialogButton
          renderDialog={({ onClose }): ReactElement => (
            <LoadMultifactorDialog
              onClose={onClose}
              handleSubmit={handleSubmit}
              multifactorQuery={getAllMultifactorsForUserQuery}
            />
          )}
          width="normal"
          disabled={!getAllMultifactorsForUserQuery.data.multifactor.getAllMultifactorsForUser.length}
        >
          Load multifactors
        </DialogButton>
      </Stack>

      <Suspense fallback={<Loader />}>
        <AssetScreenerTable
          scores={computedScores?.multifactor.computeMultifactorScores.scores as Array<IMultifactorScores>}
          ref={ref}
        />
      </Suspense>
    </Stack>
  );
}

export default AssetScreenerTab;
