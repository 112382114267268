import type { ReactElement } from 'react';
import {
  type IMultiFactor,
  IPortfolioDefinitionSubType,
  type IRebalancedPortfolioFilterInput,
  IUserPortfolioDefinitionType,
} from '../../../../../generated/graphql.tsx';
import CreatePortfolioDialog from '../create/CreatePortfolioDialog.tsx';
import {
  multifactorBasedSchema,
  type MultifactorBasedFormOutputFields,
} from './MultifactorBasedPortfolioSchema.validation.ts';
import TransactionBasedPortfolioFormFields from './TransactionBasedPortfolioFormFields.tsx';
import {
  type TransactionBasedFormOutputFields,
  transactionBasedSchema,
} from './transactionBasedPortfolioSchema.validation.ts';
import MultifactorBasedPortfolioFormFields from './MultifactorBasedPortfolioFormFields.tsx';

const CreateRebalancedPortfolioDialog = (props: {
  onClose: () => void;
  onAdded: (defId: string) => void;
  composition: { id: string; weight: number }[];
  filters: IRebalancedPortfolioFilterInput[];
  subType: IPortfolioDefinitionSubType;
  multifactors?: {
    maxFactors: number;
    multifactor: Pick<IMultiFactor, 'id' | 'name'>;
  }[];
}): ReactElement => {
  return (
    <CreatePortfolioDialog<Partial<TransactionBasedFormOutputFields> & Partial<MultifactorBasedFormOutputFields>>
      onClose={props.onClose}
      onAdded={props.onAdded}
      extraSchema={
        props.subType === IPortfolioDefinitionSubType.TransactionBased
          ? transactionBasedSchema
          : props.subType === IPortfolioDefinitionSubType.MultifactorBased
            ? multifactorBasedSchema
            : undefined
      }
      createInput={(data) => {
        return {
          name: data.name,
          description: data.description,
          type: IUserPortfolioDefinitionType.Rebalanced,
          subType: props.subType,
          composition: props.composition,
          tag: data.tag,
          multifactorRule:
            props.subType === IPortfolioDefinitionSubType.MultifactorBased
              ? {
                  filters: props.filters ?? [],
                  longAssets: data.longAssets ?? 0,
                  shortAssets: data.shortAssets ?? 0,
                  minFactors: data.minFactors ?? 0,
                  multifactorId: data.multifactor?.multifactor.id ?? 0,
                }
              : undefined,
        };
      }}
    >
      {props.subType === IPortfolioDefinitionSubType.TransactionBased && <TransactionBasedPortfolioFormFields />}
      {props.subType === IPortfolioDefinitionSubType.MultifactorBased && (
        <MultifactorBasedPortfolioFormFields multifactors={props.multifactors ?? []} />
      )}
    </CreatePortfolioDialog>
  );
};

export default CreateRebalancedPortfolioDialog;
